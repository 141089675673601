import { IRepsonseWrapper, apiClient } from ".";
import { DocumentIngestionSetting } from "../interfaces/project";

const getCallFlagDetails = async (key?: string) => {
  const { data } = await apiClient.get(`/settings/key/${key}`);
  return data;
}

const addCallFlagDetails = async (newCallFlagData: CallFlagDataRequest,settingsId: string) => {
  const { data } = await apiClient.put(`/settings/${settingsId}`, newCallFlagData);
  return data;
};

const getEmbeddingModels = async () => {
  const res = await apiClient.get<IRepsonseWrapper<DocumentIngestionSetting[]>>(
    "/setting/data-embedding"
  );
  return res;
};

export const settingsService = {
    addCallFlagDetails, 
    getCallFlagDetails, 
    getEmbeddingModels,
};


/*  --------  INTERFACES  --------  */

export interface CallFlagDataRequest{
  key?: string,
  value?:CallFlagDataValues
}
export interface CallFlagDataValues {
  claims_history?: string;
  call_history?: string;
  auth_history?: string;
}
export interface CallFlagData {
    auth?: string;
    call?: string;
    claim?: string;
}

export interface CallFlagDataResponse {
    AUTH: string;
    CALL: string;
    CLAIM: string;
}