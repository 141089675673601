import { useCallback, useState } from "react";
import { SorterResult } from "antd/es/table/interface";
import { STATUS_CODE, getOffsetFromPageAndPageSize, openNotificationWithIcon } from "../utils";
import moment from "moment";
import { FileFolder, uploadFileFolderService } from "../api";
import { usePagination } from "./usePagination";

interface ColumnSort extends SorterResult<any> {
  sortOrder: number;
  sortBy: string
}

export const useFetchDocuments = () => {
  const { page, onPaginationChange, pageSize, pageSizeOptions } = usePagination();
  const [sort, setSort] = useState({} as ColumnSort);
  const [sortBy, setSortBy] = useState("-createdAt");
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([] as FileFolder[]);
  const [total, setTotal] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(true);
  // const defaultFromDate = moment("2023-01-01", "YYYY-MM-DD") ;
  // const defaultToDate = moment();
  const defaultFromDate = moment().add(-7, "d");
  const defaultToDate = moment();
  const defaultOverviewFromDate = moment("2023-08-01 00:00:00");
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDocumentUUID, setSearchDocumentUUID] = useState("");
  const [status, setStatus] = useState("");
  const [keywords, setKeywords] = useState([] as Record<string, string>[]);
  // const [copilot, setCopilot] = useState(copilotName && getCopilotName())
  const [processingFilters, setProcessingFilters] = useState<string[]>([]);
  const [reviewFilters, setReviewFilters] = useState<string[]>([]);

  const onSortChange = (newSort: any) => {
    if (newSort.order === "ascend") setSort({ ...newSort, sortOrder: 1, sortBy: newSort.field });
    else if (newSort.order === "descend") setSort({ ...newSort, sortOrder: -1, sortBy: newSort.field });
    else setSort({ ...newSort, sortOrder: null });
  };

  const getDocumentsFilterObject = useCallback((projectId?: string) => {
    let obj: IGetDocumentsRequest = {
      limit: pageSize,
      offset: getOffsetFromPageAndPageSize(page, pageSize),
      searchTerm: searchTerm,
      searchDocumentUUID: searchDocumentUUID,
      status: status,
      projectId: projectId,
      page: page,
      sortBy:sort.sortBy,
      sortOrder: sort.sortOrder,
      processingStatus:processingFilters,
      reviewStatus:reviewFilters
    };
    if (sort.sortOrder && sort.field) {
      obj = { ...obj, sortBy: sort.field.toString(), sortOrder: sort.sortOrder };
    }
    return { ...obj };
  }, [
    page,
    pageSize,
    sort.field,
    sort.sortOrder,
    fromDate,
    toDate,
    searchTerm,
    searchDocumentUUID,
    status,
    processingFilters,
    reviewFilters
  ]);

  const fetchDocuments = useCallback(async (projectId?: string) => {
    setIsLoading(true);
    try {
      const { data } = await uploadFileFolderService.getFilesAndFolder(getDocumentsFilterObject(projectId));
      setDocuments(data.documents as FileFolder[]);
      setTotal(data.total_documents ? data.total_documents : 0);
    } catch (err: any) {
      if (err?.response?.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err?.response?.data?.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }, [getDocumentsFilterObject]);


  return {
    paginationObj: { onPaginationChange, pageSizeOptions, page, pageSize },
    sortFilter: { sort, setSort: onSortChange },
    sortBy,
    setSortBy,
    getDocumentsFilterObject,
    fetchDocuments,
    isDocumentsLoading: isLoading,
    setIsDocumentsLoading: setIsLoading,
    documents,
    totalDocuments: total,
    setDocuments,
    setTotalDocuments: setTotal,
    isAuthorized,
    defaultFromDate,
    defaultToDate,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    searchTerm,
    setSearchTerm,
    searchDocumentUUID,
    setSearchDocumentUUID,
    setStatus,
    field: keywords,
    defaultOverviewFromDate,
    setProcessingFilters,
    setReviewFilters,
    processingFilters,
    reviewFilters
  };
};
