import axios from "axios";
import { DiagnosisProps, EditPayloadType, ProcedureProps } from "../containers";
import { apiClient } from "./apiClient";

const getSummary = async (payload: IPriorAuthGetSummaryPayload) => {
  const { data } = await apiClient.post<IPriorAuthGetSummaryResponse>("/chat/query", payload);
  return { data };
};

const submitReview = async (payload: ISubmitReviewPayload, documentUUID: string) => {
  const { data } = await apiClient.put<ISubmitReviewResponse>(
    `/documents/${documentUUID}`,
    payload
  );
  return { data };
};

const generateGuidelineCompliance = async (
  documentUUID: string,
  diagnosisCode?: DiagnosisProps,
  procedureCode?: ProcedureProps,
  callFlag?: string
) => {
  const payload = callFlag
    ? {
        diagnosis: {
          code: diagnosisCode?.code,
          description: diagnosisCode?.description,
        },
        procedure: {
          code: procedureCode?.code,
          description: procedureCode?.description,
        },
        //call_flag: callFlag,
      }
    : {
        diagnosis: {
          code: diagnosisCode?.code,
          description: diagnosisCode?.description,
        },
        procedure: {
          code: procedureCode?.code,
          description: procedureCode?.description,
        },
      };
  const res = await apiClient.post(`/guideline-summary/${documentUUID}`, payload);
  return res;
};

const generateAuthorizationOverview = async (
  documentUUID: string,
  diagnosisCode?: DiagnosisProps,
  procedureCode?: ProcedureProps,
  selectedCheckboxes?: string[]
) => {
  const payload = selectedCheckboxes?.length
    ? {
        diagnosis: {
          code: diagnosisCode?.code,
          description: diagnosisCode?.description,
        },
        procedure: {
          code: procedureCode?.code,
          description: procedureCode?.description,
        },
        call_flag: selectedCheckboxes[0].toUpperCase().replace(/\s/g, "_"),
      }
    : {
        diagnosis: {
          code: diagnosisCode?.code,
          description: diagnosisCode?.description,
        },
        procedure: {
          code: procedureCode?.code,
          description: procedureCode?.description,
        },
      };
  const res = await apiClient.post(
    `/guideline-summary/overview/${documentUUID}`,
    payload
  );
  return res;
};

const fetchAnswerForAskAuto = async (q: string, documentUuid: string, containerId: string) => {
  const { data } = await apiClient.post<IAskAutoChatResponse>(`/qa`, {
    question:q,
    // metadataFilter: 0,
    // containerId,
    qaId: crypto.randomUUID(),
    document_uuid:documentUuid,
    // docVariant: "prior-auth",
    // guideline: "carelon",
  });
  return { data };
};

const submitFeedback = async (payload: FeedbackPayload) => {
  const { data } = await apiClient.post<FeedbackResponse>("/feedback", payload);
  return data;
};

const editAuthorizationData = async (summaryId:string, payload: EditPayloadType) => {
  const { data } = await apiClient.put(
    `/guideline-summary/${summaryId}`,
    payload
  );
  return data;
};

// ---------- Module Export ----------
export const priorAuthService = {
  getSummary,
  submitReview,
  generateGuidelineCompliance,
  generateAuthorizationOverview,
  fetchAnswerForAskAuto,
  submitFeedback,
  editAuthorizationData,
};

// ---------- Interfaces ----------
interface IPriorAuthGetSummaryPayload {
  q: string;
  metadataFilter: number;
  containerId: string;
  qaId: string;
  documentUuid: string;
  docVariant: string;
}

interface ISubmitReviewPayload {
  review_status: string;
}

interface ISubmitReviewResponse {
  data: {
    message: string;
  };
  status: string;
  transaction_id: string;
}

interface IPriorAuthGetSummaryResponse {
  data: {
    answer: string;
    query: string;
    sources: {
      documentUUID: string;
      pages: {
        offsets: {
          begin: number;
          end: number;
        }[];
        pageNo: number;
      }[];
      fileName: string;
    }[];
    logId: number;
    qaId: string;
  };
  status: string;
  transaction_id: string;
}

type Offset = {
  begin: number;
  end: number;
};

type Page = {
  offsets: Offset[];
  pageNo: number;
  chunkUUID:string;
};

export type GuidelineSource = {
  documentUUID: string;
  pages: Page[];
  category: string;
};

export type AdjudicationSummaryItem = {
  compliance_status: boolean;
  guideline_description: string;
  observation: string;
};

type IGuidelineComplianceResponse = {
  data: {
    guideline_sources: GuidelineSource[];
    adjudication_summary: AdjudicationSummaryItem[];
  };
  status: string;
  transaction_id: string;
};

type Data = {
  overview: string;
  adjudication_summary: string[];
  id: number;
};

type ResponseData = {
  data: Data;
  status: "OK" | string; // Adjust this if there are other possible status values
  transaction_id: string;
};

export type IAskAutoChatDataRes = {
  answer: string;
  query: string;
  sources: {
    category: string;
    documentUUID: string;
    pages: {
      offsets: {
        begin: number;
        end: number;
      }[];
      pageNo: number;
    }[];
    fileName: string;
  }[];
  logId: number;
  qaId: string;
};

export type IAskAutoChatResponse = {
  data: IAskAutoChatDataRes;
  status: string;
  transaction_id: string;
};

export type FeedbackPayload = {
  feedback: string;
  feedbackType: string;
  logId: number;
  qaId: string;
};

export type FeedbackResponse = {
  data: {
    message: string;
  };
  status: string;
  transaction_id: string;
};
