import { Entity, Trait } from '../../../api';
import { SEARCH_KEYWORD_DELIMINATOR, scoreToPercentage } from '../../../utils';

interface KeyValueMap {
  [key: string]: string;
}
export const CATEGORY_COLOR_MAPPING: KeyValueMap = {
  MEDICAL_CONDITION: '#6077E1',
  MEDICATION: '#00CACD',
  TEST_TREATMENT_PROCEDURE: '#9B67C6',
  PROTECTED_HEALTH_INFORMATION: '#95CB7A',
  ANATOMY: '#E8AE3D',
};

export enum INSIGHT_TYPE {
  MEDICAL_CONDITION = 'MEDICAL_CONDITION',
  MEDICATION = 'MEDICATION',
  TEST_TREATMENT_PROCEDURE = 'TEST_TREATMENT_PROCEDURE',
}

const CATEGORY_PRIORITY_ORDER = [
  'TEST_TREATMENT_PROCEDURE',
  'MEDICAL_CONDITION',
  'ANATOMY',
];

const CATEGORY_TYPE_TO_KEYWORD_MAPPING: KeyValueMap = {
  DX_NAME: 'DISEASE',
  PROCEDURE_NAME: 'PROCEDURE',
  TEST_NAME: 'TEST',
  TREATMENT_NAME: 'TREATMENT',
  PHONE_OR_FAX: 'CONTACT',
  SYSTEM_ORGAN_SITE: 'ANATOMY',
};

const checkTraitScoreAndEntityScore = (entity: Entity): Trait => {
  const trait = {
    name: entity.Type,
    score: entity.Score,
  };
  if (entity?.Traits?.length > 0) {
    const traitEntity = entity.Traits.reduce((prevValue, currValue) =>
      currValue.score > prevValue.score ? currValue : prevValue,
    );
    trait.name = traitEntity.name;
    trait.score = traitEntity.score;
  }
  return trait;
};

const addAttributesInEntities = (entities: Entity[]) => {
  const res: Entity[] = [];
  entities.map((entity) => {
    const entityIndex = res.findIndex((item) => item.Id === entity.Id);
    if (entityIndex === -1) res.push(entity);
    // if (entity.attribute) {
    //   entity..map((attribute) => {
    //     const attributeIndex = res.findIndex(
    //       (item) => item.id === attribute.id,
    //     );
    //     if (attributeIndex === -1) {
    //       res.push(attribute);
    //     }
    //   });
    // }
  });
  return res.sort((a, b) => a.BeginOffset - b.BeginOffset);
};

const getFilteredEntities = (
  entities: Entity[],
  selectedLegends: string[],
): Entity[] => {
  const medicalEntities = addAttributesInEntities(entities);
  const removedMedicalEntities = [] as Entity[];
  medicalEntities.forEach((entity) => {
    const startOffset = entity.BeginOffset;
    const endOffset = entity.EndOffset;
    medicalEntities.forEach((item) => {
      if (
        item.BeginOffset >= startOffset &&
        item.EndOffset <= endOffset &&
        entity.Id !== item.Id
      ) {
        const isEntityIncludedInLegends = selectedLegends.includes(
          entity.Category,
        );
        const isItemIncludedInLegends = selectedLegends.includes(item.Category);
        if (isItemIncludedInLegends && !isEntityIncludedInLegends)
          removedMedicalEntities.push(entity);
        else if (!isItemIncludedInLegends && isEntityIncludedInLegends)
          removedMedicalEntities.push(item);
        else {
          const entityPriorityIndex = CATEGORY_PRIORITY_ORDER.findIndex(
            (e) => e === entity.Category,
          );
          const itemPriorityIndex = CATEGORY_PRIORITY_ORDER.findIndex(
            (e) => e === item.Category,
          );

          if (itemPriorityIndex !== -1 && entityPriorityIndex !== -1) {
            itemPriorityIndex < entityPriorityIndex
              ? removedMedicalEntities.push(entity)
              : removedMedicalEntities.push(item);
          } else {
            itemPriorityIndex === -1
              ? removedMedicalEntities.push(item)
              : removedMedicalEntities.push(entity);
          }
        }
      }
    });
  });
  const filteredEntities = medicalEntities.filter(
    (el) => removedMedicalEntities.indexOf(el) < 0,
  );
  return filteredEntities;
};

export const getEvidenceViewerMedicalEntitiesText = (
  content: string,
  medicalEntities: Entity[],
  searchKeywords: string[],
  selectedLegends: string[],
  highlightCount: number
) => {
  const entities = getFilteredEntities(medicalEntities, selectedLegends);
  let offsetPositionChange = 0;
  entities.forEach((entity) => {
    const { name, score } = checkTraitScoreAndEntityScore(entity);
    if (selectedLegends.includes(entity.Category)) {
      const beginOffset = entity.BeginOffset + offsetPositionChange;
      const color = CATEGORY_COLOR_MAPPING[entity.Category];
      const startHtml = `<span style='background-color:${color};color:#fff;font-size:14px;margin:5px;border-radius:4px; padding:5px;' title='Score: ${scoreToPercentage(
        score,
      )}'>`;
      const endHtml = `<span style='font-family:Font-Bold;font-size:12px;padding:3px;margin:0 5px;color:${color};border-radius:4px;background-color:#fff;'>${
        CATEGORY_TYPE_TO_KEYWORD_MAPPING[name]
          ? CATEGORY_TYPE_TO_KEYWORD_MAPPING[name]
          : name
      }</span></span>`;
      content = [
        content.slice(0, beginOffset),
        startHtml,
        content.slice(beginOffset),
      ].join('');
      offsetPositionChange += startHtml.length;
      const endOffset = entity.EndOffset + offsetPositionChange;
      content = [
        content.slice(0, endOffset),
        endHtml,
        content.slice(endOffset),
      ].join('');
      offsetPositionChange += endHtml.length;
    }
  });
  content = `<p style="line-height: 2.2;font-family:Font-Regular;font-size:14px;">${content.replace(
    /(?:\r\n|\r|\n)/g,
    '<br/>',
  )}</p>`;

  if (searchKeywords && searchKeywords.length > 0) {
    searchKeywords.forEach((searchStr) => {
      const html = `<span style='border:3px solid #000;margin:5px;border-radius:6px; padding:4px 6px;'>${searchStr}</span>`;
      const expression = searchStr
      .replace(/(?:\r\n|\r|\n)/g, '<br/>')
      .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');

      const regex = new RegExp(`\\b${expression}\\b`, 'gi');
      
        let count = 0;
        content = content.replace(regex, (match) => {
          if (count < highlightCount) {
            count++;
            return html;
          }
          return match;
        });
    });
  }
  return content;
};
