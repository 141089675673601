import React, { ElementRef, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import "./guideline-compliance.scss";
import { FailureIcon, SuccessIcon } from "../../assets/icons";
import { Button, Checkbox } from "antd";
import { DislikeOutlined, DownCircleOutlined, LikeOutlined, UpCircleOutlined } from "@ant-design/icons";
import {
  AdjudicationSummaryItem,
  GuidelineSource,
  IGetSignedURLForFullSourceResponse,
  patientSummaryService,
  priorAuthService,
  uploadFileFolderService,
} from "../../api";
import { Loading } from "../../components";
import { DiagnosisProps, ProcedureProps } from "../patient-summary";
import { ReactTyped } from "react-typed";
import { MODAL_TYPE,CHECKBOX_DATA, GUIDELINES_CHECKBOXES, openNotificationWithIcon } from "../../utils";
import AskAuto from "../../components/ask-auto/AskAuto";
import { guidelineCompliance } from "../case-details-container/constants";
import { SourcePopup } from "../modals/source-popup/SourcePopup";
import { useDispatch, useSelector } from "react-redux";
import { openModal, RootState, setCurrentPage as setGlobalCurrentPage, setFeedbackType, setGuidelinesSummary, setSummaryId, setSummaryType, setCurrentChunkUUID } from "../../store";
import Markdown from "react-markdown";

type Props = {
  enableNextTab: (selectedCheckboxes: string[]) => void;
  procedureCode?: ProcedureProps;
  diagnosisCode?: DiagnosisProps;
  projectId: string;
  documentUUID: string;
  fileName: string;
};

type IOffset = {
  begin: number;
  end: number;
};

export type IPages = {
  pageNo: number;
  offsets: IOffset[];
  chunkUUID: string;
};

export type ISources = {
  category: string;
  pages: IPages[];
  documentUUID: string;
  guidelines?: boolean;
};

export const GuidelineCompliance = ({
  enableNextTab,
  procedureCode,
  diagnosisCode,
  projectId,
  documentUUID,
  fileName,
}: Props) => {
  const [chatSource, setChatSource] = useState({} as ISources);
  const [fileUrl, setFileUrl] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isComplianceCollapsed, setIsComplianceCollapsed] = useState(false);
  const [showExpandCollapse, setShowExpandCollapse] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [guidelineComplianceData, setGuidelineComplianceData] = useState(
    [] as AdjudicationSummaryItem[]
  );
  const [showNewCompliance, setShowNewCompliance] = useState(false);
  const [newComplianceData, setNewComplianceData] = useState([] as AdjudicationSummaryItem[]);
  const [clinicalGuidelineSources, setClinicalGuidelineSources] = useState([] as GuidelineSource[]);
  const [newPatientSummaryData, setNewPatientSummaryData] = useState("");
  const [newPatientSummarySources, setNewPatientSummarySources] = useState([] as GuidelineSource[]);
  const [isFaxSummaryCollapsed, setIsFaxSummaryCollapsed] = useState(false);
  const [claimsHistory, setClaimsHistory] = useState("" );
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const checkboxesRef = useRef<ElementRef<"div">>(null);
  const rerunRef = useRef<ElementRef<"div">>(null);
  const {projectDetails} = useSelector((state:RootState)=>state.document)
  const dispatch = useDispatch();
  const ClinicalGuidelineSources = [
    { category: "Criteria.pdf", documentUUID: "72cba75a-edcd-4ef3-80e2-bd1c29dd7a79", pages: [] },
  ]; 

  const generateGuidelineCompliance = async () => {
    try {
      const { data } = await priorAuthService.generateGuidelineCompliance(
        documentUUID,
        diagnosisCode,
        procedureCode
      );
      if (fileName === "Enrolee Info.pdf") {
        setGuidelineComplianceData(guidelineCompliance);
        setClinicalGuidelineSources(ClinicalGuidelineSources)
      } else {
        setGuidelineComplianceData(data?.adjudication_summary || data.data.message);
      dispatch(setGuidelinesSummary(data?.adjudication_summary))
      dispatch(setSummaryId(data?.id))
        setClinicalGuidelineSources(data?.guideline_sources || []);
      }
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  useEffect(() => {
    generateGuidelineCompliance();
  }, []);

  const handleSourceClick = async (source: ISources, pageNumber?: number,chunkUUID?:string) => {
    setFileUrl("");
    setChatSource(source);
    dispatch(setGlobalCurrentPage(pageNumber || 1))
    dispatch(setCurrentChunkUUID(chunkUUID || ""))
    try {
      const response = await uploadFileFolderService.getSignedURLForFullSource(source.documentUUID);
      const data = response.data as IGetSignedURLForFullSourceResponse;
      setFileUrl(data.presignedUrl.data.signedUrl);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
};

  const generateNewPatientSummary = async (checkboxText: string) => {
    try {
      setIsSummaryLoading(true);
      const { data } = await patientSummaryService.generatePatientSummary(
        documentUUID,
        diagnosisCode,
        procedureCode,
        checkboxText.toUpperCase().replace(/\s/g, "_")
      );
      setNewPatientSummaryData(data?.summary || "");
      setNewPatientSummarySources(data?.sources || "")
      setClaimsHistory(data?.claims_history || "");
    } catch (err: any) {
      console.log(err?.response?.data?.message);
    } finally {
      setIsSummaryLoading(false);
    }
  };

  const handleCheckboxChange =async (checked: boolean, checkboxText: string) => {
    setClaimsHistory("");
    const newSelectedCheckboxes = checked ? [checkboxText] : [];
    setSelectedCheckboxes(newSelectedCheckboxes);
    if (checked) {
      await generateNewPatientSummary(checkboxText);
    }
    if (checkboxesRef.current) {
      const claimHistoryElement = checkboxesRef.current.querySelector("#guidelines-checkboxes-group");
      if (claimHistoryElement) {
        claimHistoryElement.scrollIntoView({ behavior: "smooth" });
      }
    }

    setIsCheckboxChecked(checked);
    setIsComplianceCollapsed(true);
    setShowExpandCollapse(true);
  };

  const handleRerunCompliance = async () => {
    try {
      if (isCheckboxChecked) {
        const { data } = await priorAuthService.generateGuidelineCompliance(
          documentUUID,
          diagnosisCode,
          procedureCode,
          selectedCheckboxes[0].toUpperCase().replace(/\s/g, "_")
        );
        setNewComplianceData(data.adjudication_summary || data.message);
        setShowNewCompliance(true);
        setIsFaxSummaryCollapsed(true);
      }
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  useEffect(() => {
    if (newComplianceData.length != 0 && rerunRef.current) {
      const rerunElement = rerunRef.current.querySelector("#rerun-compliance-reference");
      if (rerunElement) {
        rerunElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [newComplianceData]);
  
  const handleGenerateAuthorization = () => {
    enableNextTab(selectedCheckboxes);
    dispatch(setSummaryType("Recommendation Summary"))
  };

  // const getSourceNumberMap = (sourcesNew: ISources[]) => {
  //   const map: { [key: string]: { source: ISources; sourceNumber: number, pageNo: number,chunkUUID:string } } = {};
  //   sourcesNew.forEach((source, index) => {
  //     source.pages.forEach((page) => {
  //       map[page.chunkUUID] = { source, sourceNumber: index + 1, pageNo: page.pageNo ,chunkUUID:page.chunkUUID};
  //     });
  //   });
  //   return map;
  // };

  // const renderAnswer = useCallback(
  //   (guidelinesSummary: string, source: ISources[]): any => {
  //     const sourceNumberMap = getSourceNumberMap(source);
  //     const uuidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g;
  //     const parts = guidelinesSummary.split(uuidRegex).map((part) =>
  //       part.trim().replace(/^[[\]]/, "").replace(/[[\]]$/, "")
  //     );
  //     const chunkUUIDs = guidelinesSummary.match(uuidRegex) || [];
  //     const processedData: any = parts.map((part, index) => {
  //       const uuid = chunkUUIDs[index];
  //       if (uuid && sourceNumberMap[uuid]) {
  //         const sourceInfo = sourceNumberMap[uuid];
  //         const sortedSourceNumbers = [sourceInfo.sourceNumber].sort((a, b) => a - b);
  //         return (
  //           <span key={index}>
  //             {part}
  //             <span
  //               className="citation-number"
  //               onClick={() => handleSourceClick(source[0], sourceInfo.pageNo,sourceInfo.chunkUUID)}
  //             >
  //               [{sortedSourceNumbers}]
  //             </span>
  //           </span>
  //         );
  //       }
  //     });
  //     return(
  //       <div>{processedData}</div>
  //       // <ReactTyped
  //       // strings={[processedData]}
  //       // typeSpeed={1}
  //       // showCursor={false}
  //       // style={{ fontSize: "16px" }}
  //       // />
  //     )
  //     ;
  //   },
  //   [clinicalGuidelineSources, guidelineComplianceData ]
  // );

  const renderGuidelinesCompilance = (): ReactNode => {
    return (
      <div className="pr m-b">
        <div ref={checkboxesRef} className="flex jcsb guidelines-guidelines">
          <div>GUIDELINE</div>
         {guidelineComplianceData.length > 0  &&  <div id="guidelines-checkboxes-group">{`COMPLIANCE ${
            guidelineComplianceData.filter((item) => item.compliance_status).length
          }/${guidelineComplianceData.length}`}</div>}
        </div>
        {!isComplianceCollapsed && guidelineComplianceData.length > 0  &&
          guidelineComplianceData.map((item, index) => (
            <div key={index} className="flex jcsb guidelines-compliance">
             {clinicalGuidelineSources.length==0 || clinicalGuidelineSources?.length==undefined || fileName=="Enrolee Info.pdf" ? (<div style={{ width: "80%" }}>{item.guideline_description}</div> ):
              (<div style={{ width: "80%" }}>
                  {item.guideline_description}
                  {/* <div className="value">{renderAnswer(item.guideline_description, clinicalGuidelineSources)}</div> */}
                </div>)}
              <div style={{ margin: "15px 30px" }}>
                {item.compliance_status ? <SuccessIcon /> : <FailureIcon />}
              </div>
            </div>
          ))}
        {showExpandCollapse &&
          (isComplianceCollapsed ? (
            <div className="guidelines-downicon">
              <DownCircleOutlined
                onClick={() => {
                  setIsComplianceCollapsed(!isComplianceCollapsed);
                }}
              />
            </div>
          ) : (
            <div className="guidelines-upicon">
              <UpCircleOutlined
                onClick={() => {
                  setIsComplianceCollapsed(!isComplianceCollapsed);
                }}
              />
            </div>
          ))}
      </div>
    );
  };

  const renderClinicalGuidelineSources = (): ReactNode => {
    return (
      <div className="source-container">
        <h5>Sources: </h5>
        <div className="source-list flex gp-10">
          {!!clinicalGuidelineSources.length &&
            clinicalGuidelineSources?.map((source, i) => (
              <li className="cursor-pointer" key={i} onClick={() => handleSourceClick(source)}>
                <a>{source.category}[{i+1}]</a>
              </li>
            ))}
        </div>
      </div>
    );
  };
  const renderCheckboxesAndComplianceData = (): ReactNode => {
    return (
      <div>
        <div className="guidelines-checkboxes">
          <div className="p-15">{CHECKBOX_DATA}</div>
          <Checkbox
            // onChange={(e) =>
            //   handleCheckboxChange(e.target.checked, GUIDELINES_CHECKBOXES.CALL_CENTER_LOGS)
            // }
            disabled={true}
          >
            Call Center Logs
          </Checkbox>
          <Checkbox 
            onChange={(e) =>
              handleCheckboxChange(e.target.checked, GUIDELINES_CHECKBOXES.CLAIMS_HISTORY)
            }
          >
            Claims History
          </Checkbox>
          <Checkbox
            // onChange={(e) =>
            //   handleCheckboxChange(e.target.checked, GUIDELINES_CHECKBOXES.AUTH_HISTORY)
            // }
            disabled={true}
          >
            Auth History
          </Checkbox>
        </div>
        {!!selectedCheckboxes.length && (
          <>
            <div style={{ marginBottom: "10px" }}>
              {selectedCheckboxes.map((checkboxText, index) => (
                <div key={index}>
                  <div className="guidelines-callFlags">
                    <div className="fw-700" key={index}>
                      {checkboxText.toUpperCase()}
                    </div>
                    {isSummaryLoading ? (
                      <Loading className="loading-icon" />
                    ) : (
                      <div className="react-typed">
                      <ReactTyped
                        strings={
                          claimsHistory
                              ? [claimsHistory.replace(/&/g, "&amp;")]
                            : ["Not generated yet"]
                        }
                        typeSpeed={1}
                        showCursor={false}
                      />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="patientData-patientSummary guidelines-callFlags"
              style={{ height: isFaxSummaryCollapsed ? "6vh" : "auto" }}
            >
              <div className="fw-700">UPDATED PATIENT HISTORY</div> {/* Previously Fax Summary */}
              {!isFaxSummaryCollapsed &&
                (isSummaryLoading ? <Loading className="loading-icon" /> : <Markdown>{newPatientSummaryData}</Markdown>
                  //renderAnswer(newPatientSummaryData,newPatientSummarySources)
                )}
              {showNewCompliance &&
                (isFaxSummaryCollapsed ? (
                  <div className="guidelines-downicon2">
                    <DownCircleOutlined
                      onClick={() => {
                        setIsFaxSummaryCollapsed(!isFaxSummaryCollapsed);
                      }}
                    />
                  </div>
                ) : (
                  <div className="guidelines-upicon2">
                    <UpCircleOutlined
                      onClick={() => {
                        setIsFaxSummaryCollapsed(!isFaxSummaryCollapsed);
                      }}
                    />
                  </div>
                ))}
            </div>
          </>
        )}
        {showNewCompliance && Array.isArray(newComplianceData) && (
          <div ref={rerunRef}>
            <div className="flex jcsb guidelines-guidelines">
              <div id="rerun-compliance-reference">GUIDELINE</div>
              <div>{`COMPLIANCE ${
                newComplianceData.filter((item) => item.compliance_status).length
              }/${newComplianceData.length}`}</div>
            </div>
            {newComplianceData.map((item, index) => (
              <div key={index} className="flex jcsb guidelines-compliance">
                {/* <div style={{ width: "80%" }}>{item.guideline_description}</div> */}
                {clinicalGuidelineSources.length==0 || clinicalGuidelineSources?.length==undefined || fileName=="Enrolee Info.pdf" ? (<div style={{ width: "80%" }}>{item.guideline_description}</div> ):
              (<div style={{ width: "80%" }}>
                  <div className="value">
                  {item.guideline_description}
                    {/* {renderAnswer(item.guideline_description, clinicalGuidelineSources)} */}
                    </div>
                </div>)}
                <div style={{ margin: "15px 30px" }}>
                  {item.compliance_status ? <SuccessIcon /> : <FailureIcon />}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderButtonActions = (): ReactNode => {
    return (
      <div className="flex gp">
        {/* <Button
          className="outline"
          // onClick={() =>
          //   handleCopyToClipboard(
          //     JSON.stringify(guidelineComplianceData),
          //     "Guideline complaince data"
          //   )
          // }
        >
          Copy to Clipboard
        </Button> */}
        <Button
          className="fill"
          onClick={handleRerunCompliance}
          disabled={!isCheckboxChecked || isSummaryLoading}
        >
          Rerun Compliance
        </Button>
        <Button className="fill" onClick={handleGenerateAuthorization} disabled={isSummaryLoading}>
          Generate Recommendation
        </Button>
      </div>
    );
  };

  const handleThumbsClick = (type: "up" | "down") => {
    dispatch(setFeedbackType(type));
    dispatch(openModal(MODAL_TYPE.FEEDBACK));
  };

  return (
    <div className="guidelines-wrapper">
      <div className="guidelines-text-container">
        <h3>Guideline Compliance</h3>
        <div className="flex jcsb guidelines-codes">
          {projectDetails?.guidelines && (<div>Guidelines : {projectDetails?.guidelines}</div>)}
          {diagnosisCode?.code && (
            <div>{`Diagnosis Code - ${diagnosisCode?.code} ${diagnosisCode?.description}`}</div>
          )}
          {procedureCode?.code && (
            <div>{`Procedure Code - ${procedureCode?.code} ${procedureCode?.description}`}</div>
          )}
        </div>
        {renderGuidelinesCompilance()}
        {renderClinicalGuidelineSources()}
        {projectDetails.projectName !== "Pharmacy Auths" && renderCheckboxesAndComplianceData()}
        {!!Object.keys(chatSource).length && (
          <SourcePopup
            chatSource={chatSource}
            setChatSource={setChatSource}
            fileUrl={fileUrl}
            guideline={projectDetails.guidelines}
            isCompliantDoc
          />
        )}
      </div>
      <div className="actions-container flex jcsb">
        <div className="patientData-footer flex">
          {/* <p>Was this helpful to you?</p>
          <LikeOutlined className="cursor-pointer flex" />{" "}
        <DislikeOutlined className="cursor-pointer flex" /> */}
          <AskAuto documentUuid={documentUUID} projectId={projectId} />
          <LikeOutlined
            className="cursor-pointer flex ask-auto-button"
            onClick={() => handleThumbsClick("up")}
          />
          <DislikeOutlined
            className="cursor-pointer flex ask-auto-button"
            onClick={() => handleThumbsClick("down")}
          />
        </div>
        {renderButtonActions()}
      </div>
    </div>
  );
};

export default GuidelineCompliance;


