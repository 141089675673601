import axios from "axios";
import { apiClient } from "./apiClient";
import { IRepsonseWrapper } from ".";
import { ISourceDetails } from "../store";
import { stringify } from "querystring";

export interface GetUploadSignedUrl {
  data: {
    signedUrl: string;
  };
  status: string;
}
export interface FileFolder {
  id: string,
  created_at: string;
  updatedAt: string;
  isFolder: boolean;
  name: string;
  processing_status: string;
  document_uuid: string;
  no_of_pages: number;
  review_status: string;
}

export interface IGetFileOCRRequest {
  pageNum: number;
  fileUuid: string;
  docVariant?: string;
  guideline?: string;
}

export interface IGetFileOCRResponse {
  fileId: string;
  index_filter: string;
  ocr_text: string;
  pageNum: string;
}

export interface IGetFileOCRResponseWrapper {
  data: IGetFileOCRResponse;
  status: string;
}

// export interface IGetFilesResponse {
//   message: string;
//   data: { files: FileFolder[] };
// }

export interface GetFilesAndFolderResponse {
  data: FileFolder[];
  status: string;
  pagination: {
    // continuationToken: string;
    limit: number;
    offset: number;
    total: number;
  };
}

export interface IGetSignedURLForFullSourceResponse {
  presignedUrl:{
  data: {
    signedUrl: string;
  }
  } 
  status: string;
}

export interface IGetMetadataResponse {
  data: { content: string };
  status: string;
}

export interface IUpdateFileFolderMetadataResponse {
  data: { message: string };
  status: string;
}

export interface IDeleteFileFolderResponse {
  data: {
    message: string;
  };
  status: string;
}

export interface IStartProcessingPreUploadedFilesResponse {
  data: {
    message: string;
  };
  status: string;
}

export interface IGetUnprocessedContainersResponse {
  data: string[];
  status: number;
}

export interface IGetFileStatusResponse {
  code: number;
  createdAt: string;
  createdBy: string;
  id: number;
  isDeleted: boolean;
  name: string;
  updatedAt: string;
  updatedBy: string;
}

export interface IGetSingleDocumentStatusResponse {
  id: number;
  uuid: string;
  containerId: number;
  documentStatusId: number;
  createdAt: string;
  createdBy: number;
  isDeleted: boolean;
  name: string;
  updatedAt: string;
  updatedBy: number;
}

// const getFiles = async (projectId: number, path?: string) => {
//   const url = path ? `/storage/files/${projectId}?path=${path}` : `/storage/files/${projectId}`;
//   const res = await apiClient.get<IGetFilesResponse>(url);
//   return res;
// };

const getUploadSignedUrl = async (fileName: string,sourceDetails:ISourceDetails) => {
  const res = await apiClient.post(`/flexstore/signedurl/upload`,{
    sourceType: sourceDetails.source_type,
    fileName: fileName,
    sourceDetails: {
      container_name:"genesis-platform-dev",
      source_type: sourceDetails.source_type,
      storage_account: sourceDetails.storage_account
    }
  });
  return res;
};

const getSignedUrlByFilepath = async (fileName: string,sourceDetails:ISourceDetails) => {
  const res = apiClient.post<IGetSignedURLForFullSourceResponse>(
    `/flexstore/signedurl/read`,{
      sourceType: sourceDetails.source_type,
      fileName: fileName,
      sourceDetails: {
        containerName: "genesis-platform-dev", //todo fetch from sourcedetails
        storageAccount: sourceDetails.storage_account,
      }
    }
  );
  return res;
};

const uploadFiles = async (url: string, file: any) => {
  const form = new FormData();
  form.append("file", file);
  const res = await axios({
    method: "put",
    url,
    data: form,
    headers: { "Content-Type": `multipart/form-data;`, "x-ms-blob-type": "BlockBlob" },
  });
  return res;
};

const getGlobalConfig = async () => {
  const res = await apiClient.get(`/global-config`);
  return res;
};

const startFileProcessing = async (
  clientId: number,
  projectId: string,
  filepath: string,
  entityId: number,
  copilotId: string,
  source_details:ISourceDetails
) => {
  const res = apiClient.post("/documents/start", {
    copilotId: copilotId,
    projectId:projectId,
    inputs: [
      {
        source: {
          sourceType: "AzureBlobStorage",
          sourceDetails: {
            storageAccount: source_details.storage_account,
            containerName: "genesis-platform-dev"
          }
        },
        info: {
          inputType: "document",
          inputFormat: "pdf"
        },
        metadata: {
          key: "value"
        },
        path: filepath
      }
    ],
    entityId: entityId,
    clientId: clientId,
    isBatch: true
  });
  return res;
};

const getDocumentStatus = async (documentUuid: string) => {
  const res = apiClient.get(`/process-file/status/${documentUuid}`);
  return res;
};

const getFilesAndFolder = async (reqObj: IGetDocumentsRequest) => {
  const res = apiClient.get(
    `/documents`,
    { params: { projectId: reqObj.projectId , caseId: reqObj.searchTerm, page:reqObj.page , pageSize: reqObj.limit , sortBy:reqObj.sortBy,sortOrder:reqObj.sortOrder, reviewStatus:reqObj.reviewStatus,processingStatus:reqObj.processingStatus } } 
  );
  return res;
};

const createFolder = async (projectId: string, folderName: string, metadata: string) => {
  const res = apiClient.post(`/storage/folder/${projectId}`, { folderName, metadata });
  return res;
};

const getSignedURLForFullSource = async (fileUuid: string) => {
  const res = apiClient.get<IGetSignedURLForFullSourceResponse>(
    `/storage/signed-url/read/file/${fileUuid}`
  );
  return res;
};


const getFileFolderMetadata = async (projectId: string, path: string) => {
  const res = await apiClient.get<IGetMetadataResponse>(
    `/storage/meta-data/${projectId}?path=${path}`
  );
  return res;
};

const updateFileFolderMetadata = async (projectId: string, path: string, content: string) => {
  const res = await apiClient.put<IUpdateFileFolderMetadataResponse>(
    `/storage/meta-data/${projectId}`,
    {
      path,
      content,
    }
  );
  return res;
};

const deleteFileFolder = async (projectId: string, path: string) => {
  const res = await apiClient.delete<IDeleteFileFolderResponse>(
    `/storage/blob/${projectId}?path=${path}`
  );
  return res;
};

const getFileOCR = async (reqObj: IGetFileOCRRequest) => {
  const res = await apiClient.post<IGetFileOCRResponseWrapper>(`/file/get-ocr`, reqObj);
  return res;
};

const startProcessingPreUploadedFiles = async (containerId: number) => {
  const res = await apiClient.get<IStartProcessingPreUploadedFilesResponse>(
    `/process-file/preuploaded-files/add/${containerId}`
  );
  return res;
};

const getUnprocessedContainers = async () => {
  const res = await apiClient.get<IGetUnprocessedContainersResponse>(
    "/storage/container/non-processed"
  );
  return res;
};

const getFileStatus = async () => {
  const res = await apiClient.get<IRepsonseWrapper<IGetFileStatusResponse[]>>(
    "/process-file/status"
  );
  return res;
};

const getSingleDocumentFileStatus = async (documentUUID: string) => {
  const res = await apiClient.get<IRepsonseWrapper<any>>(
    `/process-file/status/${documentUUID}`
  );
  return res;
};

const deleteFile =async (documentUUID:string) => {
  const res = await apiClient.delete<IRepsonseWrapper<any>>(`/documents/${documentUUID}`);
  return res;
}

export const uploadFileFolderService = {
  getUploadSignedUrl,
  uploadFiles,
  // getFiles,
  getFilesAndFolder,
  startFileProcessing,
  createFolder,
  getSignedURLForFullSource,
  getSignedUrlByFilepath,
  getFileFolderMetadata,
  updateFileFolderMetadata,
  deleteFileFolder,
  getFileOCR,
  startProcessingPreUploadedFiles,
  getUnprocessedContainers,
  getFileStatus,
  getSingleDocumentFileStatus,
  deleteFile,
  getGlobalConfig
};
