import React, { FormEvent, useEffect, useState } from "react";
import "./case-settings-container.scss";
import { Anchor, Button } from "antd";
import {  CallFlagDataValues, settingsService } from "../../../../api/settings";
import { openNotificationWithIcon } from "../../../../utils";
import { Loading } from "../../../../components";

let callFlagDetails: CallFlagDataValues = {};

const CaseSettings = () => {
  const [loading, setLoading] = useState({ formSubmit: false, callFlagDetails: true });
  const [isEditing, setIsEditing] = useState({ callFlagDetails: false });
  const [inputCallFlagDetails, setInputCallFlagDetails] = useState<Partial<CallFlagDataValues>>({});
  const [settingsId, setSettingsId] = useState("") 

  const handleToggleEdit = (formName: keyof typeof isEditing) =>
    setIsEditing((prev) => ({ ...prev, [formName]: !prev[formName] }));
  
  const handleFormOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading((prev) => ({ ...prev, formSubmit: true }));
    try {
      const newcallflagData={
        key:"case_history",
        value:inputCallFlagDetails
      }
      const  data  = await settingsService.addCallFlagDetails(newcallflagData,settingsId);
      setInputCallFlagDetails(data.value);
      callFlagDetails = data
      handleToggleEdit('callFlagDetails')
      openNotificationWithIcon("", "Settings saved", "success");
    } catch (error) {
      openNotificationWithIcon("", "Failed updating settings", "error");
    } finally {
      setLoading((prev) => ({ ...prev, formSubmit: false }));
    }
  };

  const fetchExistingCallFlagDetails = async () => {
    try {
      const key= "case_history";
      const  data  = await settingsService.getCallFlagDetails(key);
      const refactoredObj = { auth_history: data?.value.auth_history , claims_history: data?.value.claims_history, call_history: data?.value.call_history }
      setInputCallFlagDetails(refactoredObj);
      setSettingsId(data.id)
      callFlagDetails = refactoredObj
      setLoading((prev) => ({ ...prev, callFlagDetails: false }));
    } catch (error) {
      openNotificationWithIcon("", "Failed to fetch existing call flag details", "error");
    }
  };

  const handleCancel = () => {
    setIsEditing((prev) => ({ ...prev, callFlagDetails: false }));
    setInputCallFlagDetails(callFlagDetails);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setInputCallFlagDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  useEffect(() => {
    fetchExistingCallFlagDetails();
  }, []);

  const renderCallFlagDetails = () => {
    if(loading.callFlagDetails) {
      return <Loading/>
    }

    return <form className="case-settings" id="additional-info" onSubmit={handleFormOnSubmit}>
    <div className="input-container">
      <h4>Auth History</h4>
      <textarea
        name="auth_history"
        id="authHistory"
        rows={4}
        disabled={!isEditing.callFlagDetails}
        onChange={handleOnChange}
        value={inputCallFlagDetails?.auth_history || ""}
      ></textarea>
    </div>
    <div className="input-container">
      <h4>Claims History</h4>
      <textarea
        name="claims_history"
        id="claimsHistory"
        rows={4}
        disabled={!isEditing.callFlagDetails}
        onChange={handleOnChange}
        value={inputCallFlagDetails?.claims_history || ""}
      ></textarea>
    </div>
    <div className="input-container">
      <h4>Call History</h4>
      <textarea
        name="call_history"
        id="callHistory"
        rows={4}
        disabled={!isEditing.callFlagDetails}
        onChange={handleOnChange}
        value={inputCallFlagDetails?.call_history || ""}
      ></textarea>
    </div>
    <div className="case-settings-actions">
      {isEditing.callFlagDetails ? (
        <>
          <Button className="fill" onClick={handleFormOnSubmit} disabled={loading.formSubmit}>
            {loading.formSubmit ? "Saving" : "Save"}
          </Button>
          <Button className="outline" disabled={loading.formSubmit} onClick={handleCancel}>
            Cancel
          </Button>
        </>
      ) : (
        <Button className="fill" onClick={() => handleToggleEdit("callFlagDetails")}>
          Edit
        </Button>
      )}
    </div>
  </form>
  }

  return (
    <div className="case-settings-container">
      <Anchor
        affix={false}
        items={[
          {
            key: "1",
            href: "#additional-info",
            title: "Additional Info Settings",
          },
        ]}
      />
      {renderCallFlagDetails()}
    </div>
  );
};

export default CaseSettings;
