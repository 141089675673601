import React, { useEffect, useState } from "react";
import { Collapse, Input, Select, Checkbox, Button, Form, Space, Tooltip } from "antd";
import { useForm, Controller, Field } from "react-hook-form";
import { PaginationLeftArrowIcon, PaginationRightArrowIcon } from "../../assets/icons";
import "./createProjectContainer.scss";
import { useNavigate } from "react-router-dom";
import { openNotificationWithIcon } from "../../utils";
import { CallFlagDataValues, projectService, settingsService } from "../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { InfoCircleOutlined } from "@ant-design/icons";


const { Panel } = Collapse;
const { TextArea } = Input;

export enum CREATE_PROJECTS {
  GENERAL_SETTINGS = "General Settings",
  ADVANCED_SETTINGS = "Guidelines Settings",
  Q_A_CONFIGURATION = "Q & A Configuration",
  CASE_SETTINGS = "Case Settings"
}

interface PreConfiguredField {
  id?: number;
  question: string;
}

interface IQAConfiguration {
  projectPrompts?: PreConfiguredField[];
  dataFields?: Field[];
}

const preConfiguredfields = [
  { id: 1, question: "" },
  { id: 2, question: "" },
  { id: 3, question: "" },
  { id: 4, question: "" },
];

const ProjectContainer = (handleCanFetchContainers: any) => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(CREATE_PROJECTS.GENERAL_SETTINGS);
  const [submitting, setSubmitting] = useState(false);
  const [genAiModelsData, setGenAiModelsData] = useState([] as IDropdownOption[]);
  const [selectedModelId, setSelectedModelId] = useState("");
  const [guidelinesData, setGuidelinesData] = useState([] as IDropdownOption[]);
  const [embeddingModel, setEmbeddingModel] = useState<IDropdownOption>();
  const [guidelinesId,setSelectedGuidelinesId ] = useState("");
  const { author } = useSelector((state: RootState) => state.authUser);
  const newAuthor= {
    user_id: author.user_id.toString(),
    client_id: author.client_id.toString()
  }//todo remove as it should be handled in bff
  const [qaConfiguration, setQaConfiguration] = useState({} as IQAConfiguration);
  const [inputCallFlagDetails, setInputCallFlagDetails] = useState<Partial<CallFlagDataValues>>({});

  useEffect(() => {
    const dropdownsData = async () => {
      try {
        const { data: guidelines } = await projectService.getGuidelines();
        setGuidelinesData(
          guidelines.data.map((item: any) => {
            return {
              id: item._id,
              value: `${item.name}`,
            };
          })
        );
        setSelectedGuidelinesId(guidelines.data[0]._id)
        const { data: genAiModels } = await projectService.getGenerativeAiModel();
        setGenAiModelsData(
          genAiModels.map((item: any) => {
            return {
              id: item.id,
              value: `${item.name}`,
            };
          })
        );
        setSelectedModelId(genAiModels[0].id);  
        const key= "case_history";
        const  data  = await settingsService.getCallFlagDetails(key);    
        const refactoredObj = { auth_history: data?.value.auth_history , claims_history: data?.value.claims_history, call_history: data?.value.call_history }
        setInputCallFlagDetails(refactoredObj);
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    };
    dropdownsData();
  }, []);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors, isValid },
    register
  } = useForm<any>({
    mode: "all",
  });

  const handlePanelChange = (key: any) => {
    setActiveKey(key[0]);
  };

  const handleNext = () => {
    const panels = Object.values(CREATE_PROJECTS);
    const currentIndex = panels.indexOf(activeKey);

    if (currentIndex < panels.length - 1) {
      setActiveKey(panels[currentIndex + 1]);
    }
  };
  const onSubmit = async (payload: any) => {
    try {
      setSubmitting(true);
      payload.llm_model_id = selectedModelId;
      payload.guideline_id = guidelinesId;
      payload.query_config = {};
      payload.prompt_config = {};
      payload.chunk_config = {};
      payload.source_details= {
        source_type: "AzureBlobStorage",
        storage_account: "autonomizestorageaccount"
      };
      payload.author= newAuthor
      await projectService.createProject(payload);
      openNotificationWithIcon("", "Project created successfully", "success");
      navigate('/dashboard')
      handleCanFetchContainers();
    } catch (error: any) {
      openNotificationWithIcon("", error?.response?.data?.message, "error");
    } finally {
      setSubmitting(false);
    }
  };

  const renderGeneralSettingsPanel = () => {
    return (
      <Panel header={CREATE_PROJECTS.GENERAL_SETTINGS} key={CREATE_PROJECTS.GENERAL_SETTINGS}>
        <div className="form-item">
          <label htmlFor="projectName">Project Name<span className="required">*</span>{'  '}
          <Tooltip title="Enter a unique name for your project">
            <InfoCircleOutlined />
          </Tooltip>
          </label>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }: any) => <Input {...field} placeholder="Enter Name" />}
          />
          {errors.name && errors.name.type === "required" && (
            <span className="error">* Project name is required</span>
          )}
        </div>
        <div className="form-item">
          <label htmlFor="projectDescription">Project Description<span className="required">*</span>{'  '}
          <Tooltip title="Enter a unique description for your project">
            <InfoCircleOutlined />
          </Tooltip> </label>
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({ field }: any) => (
              <TextArea {...field} placeholder="Enter Description" style={{ height: "120px" }} />
            )}
          />
          {errors.description && errors.description.type === "required" && (
            <span className="error">* Project Description is required</span>
          )}
        </div>
        <div className="form-button">
          <Button onClick={handleNext} className="outline">
            Next
          </Button>
        </div>
      </Panel>
    );
  };
  const renderAdvancedSettingsPanel = () => {
    return (
      <Panel header={CREATE_PROJECTS.ADVANCED_SETTINGS} key={CREATE_PROJECTS.ADVANCED_SETTINGS}>
        <div className="form-item">
          <label htmlFor="projectName">Choose Guidelines for Adjudication {'  '}
          <Tooltip title="Choose Guidelines for adjudication from dropdown given below">
            <InfoCircleOutlined />
          </Tooltip> 
          </label>
          <Controller
            name="guideline_id"
            control={control}
            render={({ field }: any) => (
              <Select
                {...field}
                defaultActiveFirstOption
                defaultValue={guidelinesData[0]}
                placeholder="Select Guidelines"
                onChange={(value) => {
                  field.onChange(value);
                  setSelectedGuidelinesId(value);
                }}
              >
                {guidelinesData.map((model) => (
                  <Select.Option key={model.id} value={model.id}>
                    {model.value}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="form-item">
          <label htmlFor="projectName">Choose LLM Model {'  '}
          <Tooltip title="Choose LLM Model from the dropdown given below">
            <InfoCircleOutlined />
          </Tooltip> 
          </label>
          <Controller
            name="llm_model_id"
            control={control}
            render={({ field }: any) => (
              <Select
                {...field}
                defaultActiveFirstOption
                defaultValue={genAiModelsData[0]}
                placeholder="Select LLM Model"
                onChange={(value) => {
                  field.onChange(value);
                  setSelectedModelId(value);
                }}
              >
                {genAiModelsData.map((model) => (
                  <Select.Option key={model.id} value={model.id}>
                    {model.value}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </div>
      </Panel>
    );
  };
  const renderQAConfigurationPanel = () => {
    return(
      <Panel header={CREATE_PROJECTS.Q_A_CONFIGURATION} key={CREATE_PROJECTS.Q_A_CONFIGURATION}>
        <div className="single-doc">
          <h3>Define Preconfigured Prompts</h3>
          <h5>
            Please enter the Predefined question visible in the QA window (maximum of 4 questions) {'  '}
            <Tooltip title="Enter the Predefined question visible in the QA window">
            <InfoCircleOutlined />
          </Tooltip>
          </h5>
        </div>
        <div className="form-container">
          <form
            className="form-content"
            // onSubmit={handleSubmit((payload) =>
            //   onSubmitFields(payload, PROJECT_TYPE_SETTINGS.PRECONFIGURED_PROMTPS)
            // )}
          >
            <div className="field-row">
              {preConfiguredfields?.map((question, index) => (
                <div key={question.id}>
                  <div className="headings">
                    <p>Question {question.id}</p>
                  </div>
                  <div>
                    <input
                      {...register(`preConfiguredPrompts.${index}.question`)}
                      className="wide-input2"
                      defaultValue={question.question}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="qa-config-footer">
              <Button
                htmlType="submit"
                className="fill"
              >
                {"Save"}
              </Button>
            </div>
          </form>
        </div>
      </Panel>
    )
  }
  const renderCaseSettingsPanel = ()=>{
    return(
      <Panel header={CREATE_PROJECTS.CASE_SETTINGS} key={CREATE_PROJECTS.CASE_SETTINGS}>
      <div className="form-item">
          <label htmlFor="authHistory">Auth History</label>
          <Controller
            name="authHistory"
            control={control}
            rules={{ required: true }}
            defaultValue={inputCallFlagDetails.auth_history}
            render={({ field }) => <TextArea {...field} placeholder="Enter Auth History" />}
          />
          {errors.authHistory && errors.authHistory.type === "required" && (
            <span className="error">* Auth History is required</span>
          )}
        </div>
        <div className="form-item">
          <label htmlFor="claimsHistory">Claims History</label>
          <Controller
            name="claimsHistory"
            control={control}
            rules={{ required: true }}
            defaultValue={inputCallFlagDetails.claims_history}
            render={({ field }) => <TextArea {...field} placeholder="Enter Claims History" />}
          />
          {errors.claimsHistory && errors.claimsHistory.type === "required" && (
            <span className="error">* Claims History is required</span>
          )}
        </div>
        <div className="form-item">
          <label htmlFor="callHistory">Call History</label>
          <Controller
            name="callHistory"
            control={control}
            rules={{ required: true }}
            defaultValue={inputCallFlagDetails.call_history}
            render={({ field }) => <TextArea {...field} placeholder="Enter Call History" />}
          />
          {errors.callHistory && errors.callHistory.type === "required" && (
            <span className="error">* Call History is required</span>
          )}
        </div>
        </Panel>
    )
  }

  const allFieldsFilled = () => {
    const values = watch();
    return values.name && values.description;
  };

  const renderActionButtons = () => {
    return (
      <div className="create-form-button">
        <Button
          className="outline mr-10"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Cancel
        </Button>
        <Button
          className="fill"
          htmlType="submit"
         // disabled={!allFieldsFilled()}
          loading={submitting}
        >
          Create New Project
        </Button>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="create-project-form-container flex-column ">
      <Collapse
        accordion
        activeKey={activeKey}
        onChange={handlePanelChange}
        expandIconPosition="end"
        style={{ width: "70%" }}
        expandIcon={(p) =>
          p.isActive ? <PaginationLeftArrowIcon /> : <PaginationRightArrowIcon />
        }
      >
        {renderGeneralSettingsPanel()}
        {renderAdvancedSettingsPanel()}
        {renderCaseSettingsPanel()}
        {renderQAConfigurationPanel()}
      </Collapse>
      {renderActionButtons()}
    </form>
  );
};

export default ProjectContainer;
